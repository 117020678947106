export enum APP_STATE {
  FORM = 'FORM',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  INFO = 'INFO',
  EQUIPMENT = 'EQUIPMENT',
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
  SIGNATURE = 'SIGNATURE',
  STATUS = 'STATUS',
  LOCATIONS = 'LOCATIONS',
  MULTI_OWNER = 'MULTI_OWNER',
  ACH_DISTRIBUTION = 'ACH_DISTRIBUTION',
}

export enum LOADING_SIZE {
  SMALL = 'SMALL',
  FULL_SCREEN = 'FULL_SCREEN',
}

export enum LOADING_TYPE {
  SPINNER = 'SPINNER',
  OVERLAY = 'OVERLAY',
  CARD = 'CARD',
}

export enum ERROR_MESSAGE {
  ERROR = 'ERROR',
  CHANNEL = 'CHANNEL',
  LEAD = 'LEAD',
  UNAUTHORIZED = 'UNAUTHORIZED',
  CAPTCHA_FAILED = 'Captcha Failed',
  START_SESSION_FAILURE = 'Start Session Failure',
  CONTINUE_SESSION_FAILURE = 'Continue Session Failure',
  RESUME_CLIENT_SESSION_FAILURE = 'Resume Client Session Failure',
}

export enum LOADING_MESSAGE {
  REDIRECT = 'Redirecting',
  GETTING_READY = 'Almost ready...',
  SUBMIT = 'Processing',
  LOADING = 'Loading',
  TREE = 'Loading questions',
  FORM = 'Loading form',
  BANK_INFO = 'Loading bank information',
  MCC_INFO = 'Loading business types',
  PROCESSING = "We're processing your application.",
  COMPLETING_ORDER = "We're processing your order.",
  LONG = 'This is taking longer than expected... Please wait.',
  ERROR = 'There was an error during processing. Please try again.',
}

export enum Business {
  HEARTLAND = 'Heartland',
  GLOBAL = 'Global Payments Integrated',
}

export const GLOBAL_PRIVACY_TITLE: string = 'Global Payments Integrated Privacy Notice.';
export const HEARTLAND_PRIVACY_TITLE: string = 'Heartland Privacy Notice.';

export const HEARTLAND_PRIVACY_POLICY: string = 'https://www.heartlandpaymentsystems.com/privacy-policy';
export const GLOBAL_PRIVACY_POLICY: string = 'https://www.globalpaymentsintegrated.com/en-us/privacy-policy';
export const TERMS_OF_USE: string = 'https://www.heartlandpaymentsystems.com/terms-of-use';
export const HEARTLAND_US: string = 'https://heartland.us';
export const IFRAME_ELEMENT_CLASS: string = 'iframe-main';
export const UNSAFE_NAVIGATION_MESSAGE: string =
  'The previous section has already been submitted. In an attempt to help prevent complications you have been navigated back to a safer area. If you notice any issues please refresh the browser.';
