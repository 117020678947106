import './App.css';

import { RefreshModal } from './components/RefreshModal';
import ErrorBoundary from './ErrorBoundary';
import { usePhase } from './hooks';
import Layout from './layout';
import {
  AchDistribution,
  EmailVerification,
  Equipment,
  Form,
  Info,
  Locations,
  MultiOwner,
  Signature,
  Status,
} from './lazyComponents';
import { OnDemandQueryClient } from './lib/react-query';
import { useSession } from './session/hooks/useSession';
import { GLOBAL_ERROR_MESSAGE } from './types/enums';
import { APP_STATE, LOADING_MESSAGE, LOADING_TYPE } from './types/enums/appState';
import { PhaseType } from './types/phase';
import { Session } from './types/session';
import BackButtonHandler from './utility/backButtonHandler';
import { CaptchaContext } from './utility/captcha';
import { useDownloadDocument } from './utility/document';
import HeaderLoader from './utility/headerLoader';
import { useAuthUserClient, useOnDemandTheme } from './utility/hooks';
import Loading from './utility/loading';
import ScrollTopButton from './utility/scrollToTop/button';

export const queryClient = OnDemandQueryClient;

const phaseToAppState = {
  [PhaseType.INFO]: APP_STATE.INFO,
  [PhaseType.FORM]: APP_STATE.FORM,
  [PhaseType.EQUIPMENT]: APP_STATE.EQUIPMENT,
  [PhaseType.EMAIL_VERIFICATION]: APP_STATE.EMAIL_VERIFICATION,
  [PhaseType.SIGNATURE]: APP_STATE.SIGNATURE,
  [PhaseType.STATUS]: APP_STATE.STATUS,
  [PhaseType.MULTI_OWNER]: APP_STATE.MULTI_OWNER,
  [PhaseType.ACH_DISTRIBUTION]: APP_STATE.ACH_DISTRIBUTION,
  [PhaseType.LOCATIONS]: APP_STATE.LOCATIONS,
};

const getApplicationState = (isLoading: boolean, session?: Session, globalErrorMessage?: GLOBAL_ERROR_MESSAGE) => {
  const phaseType = session?.payload.phaseType;

  if (isLoading) {
    return APP_STATE.LOADING;
  }

  if (globalErrorMessage) {
    return APP_STATE.ERROR;
  }

  if (phaseType && phaseToAppState[phaseType]) {
    return phaseToAppState[phaseType];
  }

  return APP_STATE.LOADING; // Default state if none of the above conditions are met
};

function App() {
  const { isFrame } = useOnDemandTheme();

  const { refreshClientTokenError, isAuthenticated, isFetching: isFetchingAuth } = useAuthUserClient();

  const { session, isFetchingSession, isFetchingUserClientSessions, startupSessionError, handleRetry } = useSession();

  const {
    isSubmittingPhase,
    isRewindingPhase,
    captchaToken,
    sessionErrorMessage,
    allowDelay,
    handleRewind,
    submitPhaseValuesWithErrorHandling: submit,
    handleSessionChange,
    setCaptchaToken,
  } = usePhase();

  const isLoading =
    (!isAuthenticated && isFetchingAuth) ||
    isFetchingSession ||
    isFetchingUserClientSessions ||
    isSubmittingPhase ||
    isRewindingPhase;
  const loadingMessage = isSubmittingPhase ? LOADING_MESSAGE.SUBMIT : LOADING_MESSAGE.LOADING;

  const globalErrorMessage =
    refreshClientTokenError || startupSessionError
      ? ((refreshClientTokenError?.message || startupSessionError?.message) as GLOBAL_ERROR_MESSAGE)
      : undefined;

  const applicationState = getApplicationState(isLoading, session, globalErrorMessage);

  const pages = {
    [APP_STATE.INFO]: <Info submit={submit} rewind={handleRewind} />,
    [APP_STATE.FORM]: <Form submit={submit} rewind={handleRewind} isRewind={isRewindingPhase} />,
    [APP_STATE.EQUIPMENT]: <Equipment submit={submit} rewind={handleRewind} />,
    [APP_STATE.EMAIL_VERIFICATION]: <EmailVerification submit={submit} rewind={handleRewind} />,
    [APP_STATE.SIGNATURE]: <Signature submit={submit} rewind={handleRewind} />,
    [APP_STATE.STATUS]: <Status rewind={handleRewind} submit={submit} />,
    [APP_STATE.LOCATIONS]: <Locations submit={submit} rewind={handleRewind} />,
    [APP_STATE.MULTI_OWNER]: <MultiOwner submit={submit} rewind={handleRewind} />,
    [APP_STATE.ACH_DISTRIBUTION]: <AchDistribution submit={submit} rewind={handleRewind} />,
    [APP_STATE.LOADING]: (
      <>
        <Loading message={loadingMessage} delayed={allowDelay} type={LOADING_TYPE.CARD} />
        <BackButtonHandler location={0}></BackButtonHandler>
      </>
    ),
    [APP_STATE.ERROR]: null,
  };

  useDownloadDocument();

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <HeaderLoader showChatBot={session?.payload?.showChatbot}>
      <CaptchaContext.Provider value={{ token: captchaToken, setToken: setCaptchaToken }}>
        <Layout onSessionChange={handleSessionChange}>
          <ErrorBoundary
            isError={applicationState}
            message={globalErrorMessage}
            isIFrame={isFrame}
            onRetry={handleRetry}>
            <RefreshModal isOpen={Boolean(sessionErrorMessage)} onRefresh={handleRefresh} onClose={handleRefresh} />

            {pages[applicationState]}
          </ErrorBoundary>
        </Layout>
        <ScrollTopButton />
      </CaptchaContext.Provider>
    </HeaderLoader>
  );
}

export { App };
