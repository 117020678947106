import { apiFunctions } from '..';
import {
  AchDistribution,
  EmailVerification,
  Equipment,
  Form,
  Info,
  Locations,
  MultiOwner,
  Signature,
  Status,
} from '../../lazyComponents';
import { Phase, PhaseResponse, PhaseType, ReloadPhaseModelResponse } from '../../types/phase';

export const phaseSubmit = async (userProperties: any, token: string): Promise<PhaseResponse> => {
  const body = {
    CaptchaToken: token,
    UserProperties: userProperties,
  };
  return await apiFunctions.apiPost('/Phase/SubmitPhase', body);
};

export const rewind = async (): Promise<PhaseResponse> => {
  const data = await apiFunctions.apiPost('/Phase/RewindPhase', {});
  return data;
};

export async function getNextPhaseType(): Promise<Phase | null> {
  let phase: Phase | null = null;
  try {
    phase = await apiFunctions.apiPost('/Phase/GetNextPhaseType', {});

    const phaseToView = {
      [PhaseType.EQUIPMENT]: { process: () => Equipment },
      [PhaseType.INFO]: { process: () => Info },
      [PhaseType.FORM]: { process: () => Form },
      [PhaseType.EMAIL_VERIFICATION]: { process: () => EmailVerification },
      [PhaseType.SIGNATURE]: { process: () => Signature },
      [PhaseType.STATUS]: { process: () => Status },
      [PhaseType.LOCATIONS]: { process: () => Locations },
      [PhaseType.MULTI_OWNER]: { process: () => MultiOwner },
      [PhaseType.ACH_DISTRIBUTION]: { process: () => AchDistribution },
    };

    if (phase) {
      let nextPhase = phaseToView[phase.phaseType];
      if (nextPhase) {
        nextPhase.process();
      }
    }
  } catch (exc) {
    console.error(exc);
  }

  return phase;
}

export async function reloadPhaseModel(
  bypassContentInjection: boolean,
  phaseType: string,
  statusPageType: string | undefined,
): Promise<ReloadPhaseModelResponse> {
  const body = {
    bypassContentInjection,
    phaseType,
    statusPageType,
  };
  return await apiFunctions.apiPost('/Phase/ReloadPhaseModel', body);
}
